import React from 'react';
import { Helmet } from 'react-helmet';
import GameContainer from '../../components/GameContainer';
import Games from '../../components/Games';
import PageLayout from '../../components/PageLayout';
import TopNav from '../../components/TopNav';

const GamesPage = () => {
    return (
        <PageLayout>
            <Helmet>
                <title>Games - Skissan</title>
            </Helmet>
            <img src='/skissan.svg' alt='Skissan' />
            <h1>
                <span>Play drawing games with your friends!</span>
            </h1>
            <GameContainer>
                <TopNav active="games" />
                <Games />
            </GameContainer>
        </PageLayout>
    );
};

export default GamesPage;
