import React from 'react';
import { Link } from 'gatsby';
import { useAdsenseLoader, useAppDispatch, useAppSelector } from '../../hooks';
import config from '../../config';
import { io } from 'socket.io-client';
import Adsense from '../../components/Ad';
import { getWordListInfo } from '../../helpers/roomHelper';

import * as styles from './index.module.scss';

const reducer = (state, action) => {
    switch (action.type) {
        case 'message':
            // console.log('Received action:', action.event)
            const newState = {
                ...state,
                events: [...state.events, action.event],
            };

            return newState;
        case 'processed':
            const updatedEvents = [...state.events];
            updatedEvents.splice(0, action.numEventsProcessed);
            return {
                ...state,
                // events: [],
                events: updatedEvents,
            };
        default:
            console.log('Unknown action:', action);
    }
};

const Games = () => {
    useAdsenseLoader();
    const refWs = React.useRef(null);
    const refSocketClient = React.useRef(null);
    const user = useAppSelector((state) => state.user);
    const appDispatch = useAppDispatch();
    const [state, dispatch] = React.useReducer(reducer, {
        events: [],
    });
    const [isConnected, setIsConnected] = React.useState(false);
    const [numUsers, setNumUsers] = React.useState(0);
    const [numRooms, setNumRooms] = React.useState(0);
    const [version, setVersion] = React.useState('');
    const [languageFilter, setLanguageFilter] = React.useState('all');

    const [rooms, setRooms] = React.useState([]);
    const [socket, setSocket] = React.useState(null);

    React.useEffect(() => {
        const isLocal =
            typeof window !== 'undefined' &&
            window.location.hostname === 'localhost';
        const socket = io(isLocal ? config.wssLocal : config.wssProd, {
            transports: ['websocket'],
            query: {
                lobby: true,
            },
        });

        setSocket(socket);

        socket.on('connect', () => {
            setIsConnected(true);
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from Games');
        });

        socket.on('rooms', (data) => {
            // console.log('ON MESSAGE!');
            // console.log(data);
            setRooms(data.rooms);
            setNumUsers(data.numUsers);
            setNumRooms(data.numRooms);
            setVersion(data.version);
        });

        socket.on('status', (data) => {
            setNumUsers(data.numUsers);
            setNumRooms(data.numRooms);
        });

        socket.on('roomRemove', (data) => {
            setRooms((roomsLatest) => [
                ...roomsLatest.filter((room) => room.id !== data.id),
            ]);
        });

        socket.on('roomUpdate', (data) => {
            setNumUsers(data.numTotalUsers);

            setRooms((roomsLatest) => {
                const roomsUpdated = [...roomsLatest];
                let roomFound = false;
                for (let i = 0; i < roomsUpdated.length; i++) {
                    // console.log(roomsUpdated[i])
                    if (roomsUpdated[i].id === data.id) {
                        roomsUpdated[i].numUsers = data.numUsers;
                        roomFound = true;
                    }
                }

                if (!roomFound) {
                    roomsUpdated.push({
                        ...data,
                    });
                }

                return roomsUpdated;
            });
        });

        // handle the event sent with socket.send()
        socket.on('ping', (numSessions) => {
            console.log(`Ping! ${numSessions}`);
        });

        return () => {
            socket.close();
        };
    }, []);

    React.useEffect(() => {
        // Process events
        if (state.events.length === 0) {
            return;
        }
        for (let i = 0; i < state.events.length; i++) {
            const event = state.events[i];
            switch (event.type) {
                case 'rooms':
                    setRooms(event.rooms);
                    setNumUsers(event.numUsers);
                    setNumRooms(event.numRooms);
                    setVersion(event.version);
                    break;
                case 'status':
                    setNumUsers(event.numUsers);
                    setNumRooms(event.numRooms);
                    break;
                case 'join':
                    setRooms((roomsLatest) => {
                        const roomsUpdated = [...roomsLatest];
                        let roomFound = false;
                        for (let x = 0; x < roomsUpdated.length; x++) {
                            // console.log(roomsUpdated[x])
                            if (roomsUpdated[x].id === event.room.id) {
                                roomsUpdated[x].numUsers++;
                                roomFound = true;
                            }
                        }

                        if (!roomFound) {
                            roomsUpdated.push({
                                ...event.room,
                            });
                        }

                        return roomsUpdated;
                    });
                    break;
                case 'left':
                    setRooms((roomsLatest) => {
                        const roomsUpdated = [...roomsLatest];
                        for (let x = 0; x < roomsUpdated.length; x++) {
                            if (roomsUpdated[x].id === event.room.id) {
                                roomsUpdated[x].numUsers--;
                            }
                        }

                        return roomsUpdated;
                    });
                    break;
                case 'dispose':
                    setRooms((roomsLatest) => [
                        ...roomsLatest.filter(
                            (room) => room.id !== event.room.id
                        ),
                    ]);
                    break;
                default:
                    console.log(`Unknown type: ${event.type}`);
                    break;
            }
        }

        dispatch({
            type: 'processed',
            numEventsProcessed: state.events.length,
        });
    }, [state.events, rooms, setRooms]);

    return (
        <div className={styles.games}>
            <h2>Games</h2>
            {isConnected ? (
                <p style={{ display: numUsers > 1 ? 'block' : 'none' }}>
                    There are <strong>{numUsers}</strong> users online and{' '}
                    <strong>{numRooms}</strong> rooms active
                    {numRooms > rooms.length && (
                        <small> ({numRooms - rooms.length} private)</small>
                    )}
                </p>
            ) : (
                <p>
                    <i>Connecting to games</i>
                </p>
            )}

            <label htmlFor='languageFilter'>Language: </label>
            <select
                id='languageFilter'
                value={languageFilter}
                onChange={(e) => setLanguageFilter(e.target.value)}
            >
                <option value='all'>Show all</option>
                <option value='en'>English</option>
                <option value='is'>Íslenska</option>
                <option value='other'>Other</option>
            </select>

            <div className={styles.rooms}>
                <h3>Draw and guess</h3>
                <div>
                    {rooms
                        .filter(
                            (room) =>
                                (room.language === languageFilter ||
                                    languageFilter === 'all') &&
                                room.gameMode === 1
                        )
                        .map((room) => (
                            <div key={room.id} className={styles.room}>
                                <h4>{getWordListInfo(room)}</h4>
                                <div>{room.name}</div>
                                <div className={styles.info}>
                                    <span>Users</span> {room.numUsers}/
                                    {room.maxUsers}
                                </div>
                                {room.maxGuesses > 0 && (
                                    <div className={styles.info}>
                                        <span>Max guesses</span>{' '}
                                        {room.maxGuesses}
                                    </div>
                                )}
                                <div className={styles.info}>
                                    <span>Draw time</span> {room.drawTime}s
                                </div>
                                <div className={styles.info}>
                                    <span>Rounds</span> {room.rounds}
                                </div>

                                <Link to={`/room/${room.id}`} />
                            </div>
                        ))}
                    {rooms.filter(
                        (room) =>
                            room.language === languageFilter ||
                            languageFilter === 'all'
                    ).length === 0 && 'No rooms found'}
                </div>

                <h3>Draw and guess from answers</h3>
                <div>
                    {rooms
                        .filter(
                            (room) =>
                                (room.language === languageFilter ||
                                    languageFilter === 'all') &&
                                room.gameMode === 2
                        )
                        .map((room) => (
                            <div key={room.id} className={styles.room}>
                                <h4>{getWordListInfo(room)}</h4>
                                <div>{room.name}</div>
                                <div className={styles.info}>
                                    <span>Users</span> {room.numUsers}/
                                    {room.maxUsers}
                                </div>
                                <div className={styles.info}>
                                    <span>Draw time</span> {room.drawTime}s
                                </div>
                                <div className={styles.info}>
                                    <span>Rounds</span> {room.rounds}
                                </div>

                                <Link to={`/room/${room.id}`} />
                            </div>
                        ))}
                    {rooms.filter(
                        (room) =>
                            room.language === languageFilter ||
                            languageFilter === 'all'
                    ).length === 0 && 'No rooms found'}
                </div>
            </div>
            <Adsense responsive adFormat='horizontal' />
            <div>
                <div className={styles.createCustomRoom}>
                    <h3>Create a custom game</h3>
                    <p>
                        <Link to='/create/'>🐻 Create a custom game</Link>
                    </p>
                </div>
            </div>
            <div>
                <div className={styles.freeDraw}>
                    <p>
                        <Link to='/room/freedraw'>🎨 Draw alone</Link>
                    </p>
                </div>
            </div>
            <div className={styles.version}>
                <small>
                    <i>version {version}</i>
                </small>
            </div>
        </div>
    );
};

export default Games;
