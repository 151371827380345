import React from 'react';
import { useAppSelector } from '../../hooks';
import * as styles from './index.module.scss';

type Props = {
    adFormat: string;
    responsive: boolean;
    notClickable?: boolean;
};

const Ad = ({ adFormat, responsive, notClickable }: Props) => {
    const consentSettings = useAppSelector((state) => state.consent);

    React.useEffect(() => {
        // (adsbygoogle = window.adsbygoogle || []).push({});
        try {
            if (typeof window === 'object') {
                (window as any).adsbygoogle = (window as any).adsbygoogle || [];
                (window as any).adsbygoogle.pauseAdRequests = 1;
                (window as any).adsbygoogle.push({});
            }
        } catch (e) {
            console.log(e);
            // Pass
        }
    }, []);

    React.useEffect(() => {
        if (consentSettings.hasAccepted) {
            (adsbygoogle = window.adsbygoogle || []).requestNonPersonalizedAds =
                consentSettings.showPersonalizedAds ? 0 : 1;
            (adsbygoogle = window.adsbygoogle || []).pauseAdRequests = 0;
        }
    }, [consentSettings]);

    return (
        <div className={styles.adContainer}>
            <ins
                className={`adsbygoogle ${styles.ad}`}
                style={{ display: 'block' }}
                data-ad-client='ca-pub-2076439748765757'
                data-ad-slot='7795462488'
                data-ad-format={adFormat || 'auto'}
                data-full-width-responsive={responsive ? 'true' : 'false'}
                // data-adtest="on"
            />
            {notClickable && <div className={styles.coverOver} />}
        </div>
    );
};

export default Ad;
