import classNames from 'classnames';
import React from 'react';
import * as styles from './index.module.scss';

type GameContainerProps = {
    children: any;
    className?: string;
    textAlign?: 'left' | 'center';
    containerSize?: 'large' | 'medium';
};

const GameContainer = ({
    children,
    className,
    textAlign,
    containerSize,
}: GameContainerProps) => {
    return (
        <div
            className={classNames(
                containerSize && styles[containerSize],
                styles.gameContainer,
                className
            )}
            style={{ textAlign: textAlign ? textAlign : 'center' }}
        >
            {children}
        </div>
    );
};

export default GameContainer;
